import {
  Flex,
  Text,
  Image,
  useMediaQuery,
  List,
  ListItem,
} from "@chakra-ui/react";
import React from "react";
import theme from "../../theme/index";
import LYDATeam from "../../assets/LYDATeam.png";
import Seong from '../../assets/seong.png'
export const InfoLayout = () => {
  const [isSmallerThan1440] = useMediaQuery("(max-width: 1440px)");
  const [isSmallerThan1023] = useMediaQuery("(max-width: 1023px)");

  return (
    <Flex
      bg={"white.0"}
      h={"100%"}
      pt={["40px", "88px", "120px"]}
      pb={["56px", "88px", "120px"]}
      w="100%"
      px="14px"
      fontFamily={theme.fonts.main}
      justifyContent="center"
    >
      <Flex
        flexDir={"column"}
        w={["100%", "944px", "1200px"]}
        color={"blue.200"}
      >
        <Flex
          w={"100%"}
          // justifyContent={"space-evenly"}
          alignItems="center"
          mb={["48px", "88px", "120px"]}
          flexDir={isSmallerThan1023 ? "column" : "row"}
        >
          <Image
            src={LYDATeam}
            // h={["340px", "493px", "584px"]}
            w={["100%", "480px", "568px"]}
            mr={["", "64px", "132px"]}
          />
          <Flex
            flexDir={"column"}
            alignItems={isSmallerThan1023 ? "center" : ""}
          >
            <Text
              fontSize={isSmallerThan1023 ? "28px" : "40px"}
              fontWeight={600}
              mb={["8px", "16px", "24px"]}
              mt={isSmallerThan1023 ? "24px" : ""}
            >
              Founded in 2022,
            </Text>
            <Text
              w={["100%", "400px", "500px"]}
              fontSize={["14px", "16px", "18px"]}
              fontStyle="normal"
              fontWeight={400}
              lineHeight={["22.04px", "25.18px", "32px"]}
            >
              LYDA is a social token platform that quantifies the social capital
              generated by the fandoms of digital content creators, and converts
              said social capital into real capital in an effort to financially
              support creators and sustain a circulating economic structure. By
              enabling fandom-based social capital, fans may actively contribute
              to the longevity of their favorite brand’s business while being
              rewarded for their active participation in the community. To
              achieve this, LYDA platform offers creators the opportunity to
              create their own social token and build a community economy. In
              doing so, LYDA reduces the Web3 barrier to entry for creators
              while providing functions that that directly connect individuals
              to their fandoms main stakeholder.
            </Text>
          </Flex>
        </Flex>
        <Flex
          w={["100%", "944px", "1200px"]}
          alignItems="center"
          flexDir={isSmallerThan1023 ? "column-reverse" : "row"}
        >
          <Flex flexDir={"column"} mt={isSmallerThan1023 ? "24px" : ""}  alignItems={isSmallerThan1023 ? "center" : ""}>
            <Text
              color="#666d8b"
              fontSize={isSmallerThan1023 ? "16px" : "18px"}
              fontStyle="normal"
              fontWeight={600}
            >
              Project Director
            </Text>
            <Text
              fontSize={"40px"}
              fontWeight={600}
              alignItems={isSmallerThan1023 ? "center" : ""}
              mb={isSmallerThan1023 ? "8px" : "24px"}
              lineHeight="63px"
            >
              Seong Song
            </Text>
            <Text
              w={["100%", "400px", "500px"]}
              fontSize={["14px", "16px", "18px"]}
              fontStyle="normal"
              lineHeight={["22.04px", "25.18px", "32px"]}
              fontWeight={400}
              textAlign={isSmallerThan1023? 'center':'left'}
            >
              Among the various aspects of each crypto projects, Seong most
              values realization of user ecosystem based on its own intended
              utility.
            </Text>
            <List>
              <ListItem
                w={["100%", "400px", "500px"]}
                fontSize={["14px", "16px", "18px"]}
                fontStyle="normal"
                lineHeight={["22.04px", "25.18px", "32px"]}
                fontWeight={400}
              >
                - CGO, Onther Inc
              </ListItem>
              <ListItem
                w={["100%", "400px", "500px"]}
                fontSize={["14px", "16px", "18px"]}
                fontStyle="normal"
                lineHeight={["22.04px", "25.18px", "32px"]}
                fontWeight={400}
              >
                - CEO, ESportsConnected
              </ListItem>
              <ListItem
                w={["100%", "400px", "500px"]}
                fontSize={["14px", "16px", "18px"]}
                fontStyle="normal"
                lineHeight={["22.04px", "25.18px", "32px"]}
                fontWeight={400}
              >
                - Sr. Manager, PwC
              </ListItem>
              <ListItem
                w={["100%", "400px", "500px"]}
                fontSize={["14px", "16px", "18px"]}
                fontStyle="normal"
                lineHeight={["22.04px", "25.18px", "32px"]}
                fontWeight={400}
              >
                - UCLA, Business Economics
              </ListItem>
            </List>
          </Flex>
          <Image
            src={Seong}
            w={["100%", "480px", "568px"]}
            ml={["", "64px", "132px"]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
